


































































































































import { Component, Vue } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import { mapState } from 'vuex';
import IVariant from '@/interfaces/IVariant';

import eventTypesQuery from '@/graphql/event/EventTypes.query.gql';
import eventCategoriesQuery from '@/graphql/event/EventCategories.query.gql';
import interestedTimesQuery from '@/graphql/me/account/InterestedTimes.query.gql';
import interestedInMutation from '@/graphql/me/account/UpdateUserInterestedSettings.mutation.gql';

import Collapse from '@/components/partials/elements/Collapse.vue';
import BackHeader from '@/components/partials/BackHeader.vue';
import TheCheckedMarkIcon from '@/components/icons/TheCheckedMarkIcon.vue';
import TheArrowHorizIcon from '@/components/icons/TheArrowHorizIcon.vue';
import Checkbox from '@/components/partials/elements/Checkbox.vue';

type UserInterestedInType = 'types' | 'categories' | 'times';

@Component({
  components: {
    TheArrowHorizIcon,
    TheCheckedMarkIcon,
    BackHeader,
    Collapse,
    Checkbox,
  },
  metaInfo: {
    title: "I'm interested in",
  },
  computed: {
    ...mapState(['museumRole', 'isMobileScreenExclude768']),
  },
  apollo: {
    eventTypes: {
      query: eventTypesQuery,
      result(result: any) {
        if (result.data.eventTypes) {
          this.types = result.data.eventTypes;
        }
      },
    },
    eventCategories: {
      query: eventCategoriesQuery,
      result(result: any) {
        if (result.data.eventCategories) {
          this.category = result.data.eventCategories;
        }
      },
    },
    UserSettingsToPick: {
      query: interestedTimesQuery,
      result(result: any) {
        if (result.data.UserSettingsToPick.interestedTimes) {
          this.times = result.data.UserSettingsToPick.interestedTimes;
        }
      },
    },
  },
})
export default class IamInterestedIn extends Vue {
  checkDataType: any[] = [];
  checkDataCategories: any[] = [];
  checkDataTimes: any[] = [];
  types: any[] = [];
  category: any[] = [];
  times: any[] = [];
  isAllTypes = false;
  isAllTimes = false;
  isAllCategories = false;
  isTypes = false;
  isCategories = false;
  isTimes = false;
  showModal = false;
  currentModalTitle = '';

  created() {
    this.$root.$on('closeModalProfile', () => {
      this.showModal = false;
    });
  }

  onShowCollapseSection(isVisible: boolean, type: UserInterestedInType) {
    if (!isVisible) {
      return;
    }
    switch (type) {
      case 'types':
        this.isTypes = true;
        this.isCategories = false;
        this.isTimes = false;
        break;
      case 'categories':
        this.isTypes = false;
        this.isCategories = true;
        this.isTimes = false;
        break;
      case 'times':
        this.isTypes = false;
        this.isCategories = false;
        this.isTimes = true;
        break;
    }
  }

  hide(type: string) {
    switch (type) {
      case 'types':
        this.showModal = true;
        this.isTypes = true;
        this.isCategories = false;
        this.isTimes = false;
        this.currentModalTitle = 'Types';
        break;
      case 'category':
        this.showModal = true;
        this.isTypes = false;
        this.isTimes = false;
        this.isCategories = true;
        this.currentModalTitle = 'Categories';
        break;
      case 'times':
        this.showModal = true;
        this.isTypes = false;
        this.isCategories = false;
        this.isTimes = true;
        this.currentModalTitle = 'Times';
        break;
    }
  }

  toggleTypes(variant: IVariant) {
    this.checkDataType = [];
    this.isAllTypes = false;

    this.types.forEach((value: any) => {
      if (value.id == variant.id) {
        value.selected = variant.selected;
      }
      if (value.selected) {
        this.checkDataType.push(value.id);
      }
    });
    this.changeUserInterestedInItems('types', this.checkDataType);
  }

  toggleCategories(variant: IVariant) {
    this.checkDataCategories = [];
    this.isAllCategories = false;

    this.category.forEach((value: any) => {
      if (value.id == variant.id) {
        value.selected = variant.selected;
      }
      if (value.selected) {
        this.checkDataCategories.push(value.id);
      }
    });
    this.changeUserInterestedInItems('categories', this.checkDataCategories);
  }

  toggleTimes(variant: IVariant) {
    this.checkDataTimes = [];
    this.isAllTimes = false;

    this.times.forEach((value: any) => {
      if (value.id == variant.id) {
        value.selected = variant.selected;
      }
      if (value.selected) {
        this.checkDataTimes.push(value.id);
      }
    });
    this.changeUserInterestedInItems('times', this.checkDataTimes);
  }

  @Debounce(1000)
  changeUserInterestedInItems(type: UserInterestedInType, items: any[]) {
    this.$apollo.mutate({
      mutation: interestedInMutation,
      variables: {
        [type]: items,
      },
    });
  }

  checkAll(data: string) {
    let arr: any[] = [];
    switch (data) {
      case 'types':
        this.isAllTypes = !this.isAllTypes;
        if (this.isAllTypes) {
          this.types.forEach((value: any) => {
            if (value) {
              value.selected = true;
              arr.push(value.id);
            }
          });
        } else {
          arr = [];
          this.types.forEach((value: any) => {
            if (value) {
              value.selected = false;
            }
          });
        }
        this.changeUserInterestedInItems('types', arr);
        break;
      case 'category':
        this.isAllCategories = !this.isAllCategories;
        if (this.isAllCategories) {
          this.category.forEach((value: any) => {
            if (value) {
              value.selected = true;
              arr.push(value.id);
            }
          });
        } else {
          arr = [];
          this.category.forEach((value: any) => {
            if (value) {
              value.selected = false;
            }
          });
        }
        this.changeUserInterestedInItems('categories', arr);
        break;
      case 'times':
        this.isAllTimes = !this.isAllTimes;
        if (this.isAllTimes) {
          this.times.forEach((value: any) => {
            if (value) {
              value.selected = true;
              arr.push(value.id);
            }
          });
        } else {
          arr = [];
          this.times.forEach((value: any) => {
            if (value) {
              value.selected = false;
            }
          });
        }
        this.changeUserInterestedInItems('times', arr);
        break;
    }
  }
}
