























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import CollapseTransition from '@/components/transitions/CollapseTransition.vue';
import TheArrowDownIcon from '@/components/icons/TheArrowDownIcon.vue';

@Component({
  components: { TheArrowDownIcon, CollapseTransition },
})
export default class Collapse extends Vue {
  @Prop() title!: string;
  @Prop({ default: false, type: Boolean }) opened!: boolean;
  @Prop({ default: false, type: Boolean }) small!: boolean;

  showCollapse = false;
  boxCntClasses: { [className: string]: boolean } = {};

  get getId() {
    return 'collapse-' + this.title.split(' ').join('-').toLowerCase();
  }

  @Watch('opened')
  watchOpened(isVisible: boolean) {
    if (this.showCollapse !== isVisible) {
      this.toggle(isVisible);
    }
  }

  created() {
    this.showCollapse = this.opened;
  }

  toggle(isVisible: boolean | null = null) {
    this.showCollapse = typeof isVisible === 'boolean' ? isVisible : !this.showCollapse;
    this.$emit('show', this.showCollapse);

    if (window.innerWidth < 768) {
      this.boxCntClasses = {
        'left-menu': true,
        'mobile-modal': true,
        'mobile-modal--full': true,
      };
      this.$store.dispatch('disableScroll');
    }
  }
}
